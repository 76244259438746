registerProcessor(
  'ClockProcessor',
  class ClockProcessor extends AudioWorkletProcessor {
    static get parameterDescriptors() {
      return [
        {
          name: 'rate',
          defaultValue: 2,
          minValue: 0,
          automationRate: 'k-rate',
        },
        {
          name: 'swing',
          defaultValue: 0,
          minValue: 0,
          maxValue: 1,
          automationRate: 'k-rate',
        },
        {
          name: 'jitter',
          defaultValue: 0,
          minValue: 0,
          maxValue: 1,
          automationRate: 'k-rate',
        },
        {
          name: 'reset',
          defaultValue: 0,
          minValue: -1,
          maxValue: 1,
          automationRate: 'k-rate',
        },
      ];
    }

    lastReset = 0;
    remaining = 0;
    downbeat = false;

    process(
      _: Float32Array[][],
      [[output]]: Float32Array[][],
      {
        rate: [rate],
        swing: [swing],
        jitter: [jitter],
        reset: [reset],
      }: { [param: string]: Float32Array },
    ): boolean {
      if (this.lastReset <= 0 && reset > 0) {
        this.remaining = 0;
        this.downbeat = false;
      }
      this.lastReset = reset;

      if (this.remaining < output.length) {
        output[0] = 1;
        const duration = (1 / rate) * sampleRate;
        const swingAdjustment = (this.downbeat ? 1 : -1) * swing;
        const jitterAdjustment = (Math.random() * 2 - 1) * jitter;
        this.remaining += (1 + swingAdjustment + jitterAdjustment) * duration;
        this.downbeat = !this.downbeat;
        this.port.postMessage(['trigger']);
      }

      this.remaining -= output.length;

      return true;
    }
  },
);
